<template>
  <base-wrapper :loading="loading">
    <base-card title="Data Pembayaran Stockist">
      <div class="space-y-4">
        <div class="flex items-end justify-end gap-2">
          <base-input label="Asal Faktur">
            <base-select
              :shadow="false"
              :options="[
                { key: 'null', label: 'Semua', value: null },
                { key: 'pusat_kp', label: 'Pusat dan KP', value: 'pusat_kp' },
                { key: 'stockist', label: 'Stockist', value: 'stockist' },
              ]"
              v-model="filter.originType"
              @change="loadOrders"
            />
          </base-input>
          <base-input label="Status">
            <base-select
              :shadow="false"
              :options="[
                { key: 'null', label: 'Semua', value: null },
                { key: 'belum_lunas', label: 'Belum Lunas', value: false },
                { key: 'lunas', label: 'Lunas', value: true },
              ]"
              v-model="filter.is_paid"
              @change="loadOrders"
            />
          </base-input>
          <base-input
            :shadow="false"
            placeholder="Cari kode"
            type="search"
            debounce
            v-model="filter.search"
            @native-input="loadOrders"
          />
        </div>
        <datatable
          :columns="tableColumns"
          :total="orders.meta.page.total"
          :perPage="orders.meta.page.perPage"
          :currentPage="orders.meta.page.currentPage"
          @pagechanged="onChangePage"
        >
          <template #tbody="{ classes }">
            <tr
              v-for="order in orders.data"
              :key="order.id"
              :class="[classes.tr, 'cursor-pointer hover:bg-green-100']"
              @click="
                $router.push({
                  name: 'pembayaran.ke-stockist.detail',
                  params: { id: order.relationships.order.data.id },
                })
              "
            >
              <td :class="classes.td">
                <p>
                  <span class="font-bold text-gray-900">{{
                    order.attributes.origin_code
                  }}</span>
                  /
                  <span class="font-medium">{{
                    order.attributes.destination_code
                  }}</span>
                </p>
                <order-date-label :order="order" class="text-xs text-gray-400" />
              </td>
              <td :class="classes.td">
                {{ getDetailOrder(order).attributes.destination_office_code }}
              </td>
              <td :class="classes.td">
                {{ getDetailOrder(order).attributes.origin_warehouse_code }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{
                  Math.max(
                    getDetailOrder(order).attributes.grand_total_price -
                      getDetailOrder(order).attributes.cashier_payment_amount,
                    0
                  ) | toCurrency
                }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{
                  getDetailOrder(order).attributes.grand_total_price
                    | toCurrency
                }}
              </td>
              <td :class="[classes.td, 'text-center']">
                <base-badge
                  :color="
                    getDetailOrder(order).attributes.is_paid ? 'green' : 'red'
                  "
                  >{{
                    getDetailOrder(order).attributes.is_paid
                      ? 'Lunas'
                      : 'Belum Lunas'
                  }}</base-badge
                >
              </td>
              <td :class="[classes.td, 'text-center']">
                <base-badge
                  :color="
                    getDetailOrder(order).attributes.is_cancel
                      ? 'red'
                      : 'yellow'
                  "
                  >{{  getDetailOrder(order).attributes.order_status }}</base-badge
                >
              </td>
            </tr>
          </template>
        </datatable>
      </div>
    </base-card>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import OrderDateLabel from '@/components/order/order-date-label.vue';

export default {
  mixins: [requestMixin],
  components: { OrderDateLabel },
  data() {
    return {
      filter: {
        originType: 'pusat_kp',
        is_paid: false,
        search: null,
      },
      loading: true,
      orders: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    tableColumns() {
      return [
        { id: 'code', name: 'No. Faktur' },
        { id: 'stockist_code', name: 'Kode Stockist' },
        { id: 'warehouse_code', name: 'Kode Gudang' },
        { id: 'payment', name: 'Sisa Pembayaran', theadClass: 'text-right' },
        {
          id: 'total_price',
          name: 'Total Penjualan',
          theadClass: 'text-right',
        },
        { id: 'payment_status', name: 'Status', theadClass: 'text-center' },
        { id: 'order_status', name: 'Status Order', theadClass: 'text-center' },
      ];
    },
  },
  methods: {
    getDetailOrder(order) {
      return this.getSingleIncluded(
        this.orders,
        order.relationships.order.data.id
      );
    },
    async loadOrders(params) {
      this.loading = true;

      const [res, err] = await this.request(`/api/v1/orders`, {
        params: {
          include: 'order',
          'fields[simple-orders]':
            'origin_code,destination_code,orderedAt,createdAt,order',
          'fields[orders]':
            'destination_office_code,origin_warehouse_code,payment_amount,cashier_payment_amount,grand_total_price,order_status,is_cancel,is_paid',
          'filter[search]': this.filter.search,
          'filter[is_paid]': this.filter.is_paid,
          'filter[not-order_status]': 'Draft,Batal Faktur Kasir',
          'filter[destination_office_category_id]': 4,
          'filter[origin_office_category_ids]': '1,2',
          'page[size]': 5,
          sort: '-id',
          ...params,
        },
      });

      if (!err) {
        this.orders = res;
      }

      this.loading = false;
    },
    onChangePage(page) {
      this.loadOrders({
        'page[number]': page,
      });
    },
  },
  created() {
    this.loadOrders();
  },
};
</script>
