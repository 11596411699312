var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',{attrs:{"loading":_vm.loading}},[_c('base-card',{attrs:{"title":"Data Pembayaran Stockist"}},[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex items-end justify-end gap-2"},[_c('base-input',{attrs:{"label":"Asal Faktur"}},[_c('base-select',{attrs:{"shadow":false,"options":[
              { key: 'null', label: 'Semua', value: null },
              { key: 'pusat_kp', label: 'Pusat dan KP', value: 'pusat_kp' },
              { key: 'stockist', label: 'Stockist', value: 'stockist' },
            ]},on:{"change":_vm.loadOrders},model:{value:(_vm.filter.originType),callback:function ($$v) {_vm.$set(_vm.filter, "originType", $$v)},expression:"filter.originType"}})],1),_c('base-input',{attrs:{"label":"Status"}},[_c('base-select',{attrs:{"shadow":false,"options":[
              { key: 'null', label: 'Semua', value: null },
              { key: 'belum_lunas', label: 'Belum Lunas', value: false },
              { key: 'lunas', label: 'Lunas', value: true },
            ]},on:{"change":_vm.loadOrders},model:{value:(_vm.filter.is_paid),callback:function ($$v) {_vm.$set(_vm.filter, "is_paid", $$v)},expression:"filter.is_paid"}})],1),_c('base-input',{attrs:{"shadow":false,"placeholder":"Cari kode","type":"search","debounce":""},on:{"native-input":_vm.loadOrders},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('datatable',{attrs:{"columns":_vm.tableColumns,"total":_vm.orders.meta.page.total,"perPage":_vm.orders.meta.page.perPage,"currentPage":_vm.orders.meta.page.currentPage},on:{"pagechanged":_vm.onChangePage},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.orders.data),function(order){return _c('tr',{key:order.id,class:[classes.tr, 'cursor-pointer hover:bg-green-100'],on:{"click":function($event){return _vm.$router.push({
                name: 'pembayaran.ke-stockist.detail',
                params: { id: order.relationships.order.data.id },
              })}}},[_c('td',{class:classes.td},[_c('p',[_c('span',{staticClass:"font-bold text-gray-900"},[_vm._v(_vm._s(order.attributes.origin_code))]),_vm._v(" / "),_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(order.attributes.destination_code))])]),_c('order-date-label',{staticClass:"text-xs text-gray-400",attrs:{"order":order}})],1),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.getDetailOrder(order).attributes.destination_office_code)+" ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.getDetailOrder(order).attributes.origin_warehouse_code)+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(Math.max( _vm.getDetailOrder(order).attributes.grand_total_price - _vm.getDetailOrder(order).attributes.cashier_payment_amount, 0 )))+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getDetailOrder(order).attributes.grand_total_price))+" ")]),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":_vm.getDetailOrder(order).attributes.is_paid ? 'green' : 'red'}},[_vm._v(_vm._s(_vm.getDetailOrder(order).attributes.is_paid ? 'Lunas' : 'Belum Lunas'))])],1),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":_vm.getDetailOrder(order).attributes.is_cancel
                    ? 'red'
                    : 'yellow'}},[_vm._v(_vm._s(_vm.getDetailOrder(order).attributes.order_status))])],1)])})}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }