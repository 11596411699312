<template>
  <div
    slot="pdf-content"
    class="pdf-content mb-4 w-full space-y-2 px-6 py-2 text-sm"
  >
    <div>
      <p>PT. NATURAL NUSANTARA</p>
      <p>Jl. Ring Road Barat No. 72 Salakan Trihanggo</p>
      <p>Gamping Sleman Jogjakarta 55291</p>
      <p>Telp:0274-6499191(Hunting) Fax:0274-6499192/6499193</p>
    </div>
    <p class="text-base font-bold">TANDA TERIMA PEMBAYARAN</p>
    <div class="flex justify-between">
      <table>
        <tr>
          <td class="pr-4">NO. INVOICE</td>
          <td>:</td>
          <td class="pl-2">{{ payment ? payment.attributes.code : '-' }}</td>
        </tr>
        <tr>
          <td>TGL.INVOICE</td>
          <td>:</td>
          <td class="pl-2">
            <span v-if="payment">{{
              payment.attributes.createdAt | formatDate('DD/MM/YY')
            }}</span>
            <span v-else>-</span>
          </td>
        </tr>
        <tr>
          <td>NO. FAKTUR</td>
          <td>:</td>
          <td class="pl-2">{{ order.attributes.origin_code }}</td>
        </tr>
        <tr>
          <td>TTL.FAKTUR</td>
          <td>:</td>
          <td class="pl-2">
            {{ order.attributes.grand_total_price | toCurrency }}
          </td>
        </tr>
      </table>
      <div>
        <p>
          STOCKIST :
          {{ destinationOffice ? destinationOffice.attributes.code : '-' }}
        </p>
        <p>{{ destinationOffice ? destinationOffice.attributes.name : '-' }}</p>
      </div>
    </div>
    <div class="pl-10">
      <table>
        <tr>
          <td>PEMBAYARAN</td>
        </tr>
        <tr
          v-for="(paymentMethod, index) in paymentMethods"
          :key="paymentMethod.id"
        >
          <td
            :class="
              index === paymentMethods.length - 1 ? 'pl-4 pb-2' : 'pl-4 pr-10'
            "
          >
            {{ paymentMethod.attributes.payment_method_type }}
          </td>
          <td>:</td>
          <td
            :class="
              index === paymentMethods.length - 1
                ? 'border-b border-black pl-4 pb-2 text-right'
                : 'pl-4 text-right'
            "
          >
            {{ paymentMethod.attributes.cashier_payment_amount | toCurrency }}
          </td>
        </tr>
        <tr>
          <td>JML. DITERIMA</td>
          <td>:</td>
          <td class="pl-4 text-right">
            {{ order.attributes.cashier_payment_amount | toCurrency }}
          </td>
        </tr>
        <tr>
          <td>KETERANGAN</td>
          <td>:</td>
        </tr>
      </table>
    </div>
    <div class="pt-6">
      <p class="mb-10 pb-6">
        YOGYAKARTA,
        <span v-if="payment">{{
          payment.attributes.createdAt | formatDate('DD/MM/YY HH:mm:ss')
        }}</span>
      </p>
      <p class="mt-4 border-b border-black pb-4">
        {{ me.name }} ({{ me.current_role }})
      </p>
      <p>SALDO BELANJA DAN PEMBAYARAN PER 26/02/25</p>
      <p v-if="destinationOffice">
        {{ destinationOffice.attributes.order_balance_available | toCurrency }}
      </p>
      <p v-else>-</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    order: Object,
    payment: Object,
    destinationOffice: Object,
    paymentMethods: Array,
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
  },
};
</script>

<style scoped>
.pdf-content {
  font-family: 'Courier New', Courier, monospace !important;
}
</style>
