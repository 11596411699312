<template>
  <base-wrapper :loading="loading">
    <div class="space-y-6">
      <payment-order-detail-card :order="order" />

      <template v-if="pageLoaded">
        <create-payment-form
          v-if="needCreatePayment"
          :min-amount="order.data.attributes.grand_total_price"
          :paid="paidAmount"
          @save="onCreatePayment"
        />

        <payment-list
          :order-id="order.data.id"
          :payments="payments"
          :order-price="order.data.attributes.grand_total_price"
          editable
          @reload="loadPayments"
          @verified="onVerifiedPayment"
        />

        <base-card v-if="accAtasanFormShown" title="Acc Atasan">
          <base-input
            inset
            label="Acc Atasan"
            placeholder="Masukkan deskripsi acc atasan"
            tag="textarea"
            rows="8"
            v-model="accAtasanReason"
          />
        </base-card>

        <div
          :class="[
            'flex',
            order.data.attributes.is_valid_for_packing
              ? 'justify-end'
              : 'justify-between',
          ]"
        >
          <base-button
            v-if="!order.data.attributes.is_valid_for_packing"
            color="white"
            @click="visibleDeleteConfirm = true"
          >
            <Icon icon="heroicons:x-mark-solid" />
            Batalkan Faktur Penjualan
          </base-button>
          <div class="flex items-center gap-2">
            <base-button v-if="accAtasanFormShown" @click="onSubmitAccAtasan">
              <Icon icon="heroicons:document-solid" />
              Acc Atasan
            </base-button>
            <base-button
              v-if="order.data.attributes.is_paid"
              @click="onPrintInvoice"
            >
              <Icon icon="heroicons:printer-solid" />
              Cetak Invoice
            </base-button>
            <base-button :to="{ name: 'pembayaran.ke-stockist' }" color="white">
              <Icon icon="heroicons:arrow-uturn-left-solid" />
              Kembali
            </base-button>
          </div>
        </div>
      </template>
    </div>

    <base-confirm
      message="Sudah ada faktur pembayaran. Anda yakin ingin membatalkan?"
      :visible="visibleDeleteConfirm"
      @close="visibleDeleteConfirm = false"
      @confirmed="onConfirmDelete"
    />

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="false"
      :paginate-elements-by-height="5000"
      :filename="order.data.attributes.origin_code"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="210mm"
      ref="printInvoince"
    >
      <print-invoice-pdf
        slot="pdf-content"
        :order="order.data"
        :payment="payments.data[0]"
        :payment-methods="printInvoice.paymentMethods"
        :destination-office="orderDestinationOffice"
      />
    </vue-html2pdf>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import PaymentOrderDetailCard from '@/components/payment/payment-order-detail-card.vue';
import CreatePaymentForm from '@/components/payment/create-payment-form.vue';
import PaymentList from '@/components/payment/payment-list.vue';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import { mapActions } from 'vuex';
import VueHtml2pdf from 'vue-html2pdf';
import PrintInvoicePdf from '@/components/order/print-invoice-pdf.vue';

export default {
  components: {
    PaymentOrderDetailCard,
    CreatePaymentForm,
    PaymentList,
    BaseConfirm,
    VueHtml2pdf,
    PrintInvoicePdf,
  },
  mixins: [requestMixin],
  data() {
    return {
      accAtasanReason: null,
      order: {
        data: {
          attributes: {},
          relationships: {
            'destination-office': {
              data: {
                id: null,
              },
            },
          },
        },
        included: [],
      },
      payments: {
        data: [],
      },
      printInvoice: {
        paymentMethods: [],
      },
      loadingOrder: true,
      loadingCreatePayment: false,
      loadingAccAtasan: false,
      loadingPayments: false,
      loadingCancel: false,
      loadingPrintInvoice: false,
      pageLoaded: false,
      visibleDeleteConfirm: false,
    };
  },
  computed: {
    loading() {
      return (
        this.loadingOrder ||
        this.loadingCreatePayment ||
        this.loadingPayments ||
        this.loadingCancel ||
        this.loadingAccAtasan ||
        this.loadingPrintInvoice
      );
    },
    orderDestinationOffice() {
      return this.getSingleIncluded(
        this.order,
        this.order.data.relationships['destination-office'].data.id
      );
    },
    needCreatePayment() {
      if (!this.payments.data.length) {
        return true;
      }

      if (
        this.payments.data.some((payment) => !payment.attributes.is_verified)
      ) {
        return false;
      }

      if (!this.order.data.attributes.is_paid) {
        return true;
      }

      return false;
    },
    paidAmount() {
      return this.payments.data.reduce(
        (total, payment) => total + payment.attributes.cashier_total_amount,
        0
      );
    },
    accAtasanFormShown() {
      return (
        this.needCreatePayment && !this.order.data.attributes.acc_payment_reason
      );
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async createPaymentMethods(payment, paymentMethods) {
      const requests = paymentMethods.map((paymentMethod) => {
        return this.request(`/api/v1/payment-methods`, {
          method: 'post',
          data: {
            data: {
              type: 'payment-methods',
              attributes: {
                cashier_payment_amount: Number(
                  paymentMethod.amount.replace(/\D/gi, '')
                ),
                payment_amount: 0,
                receipt_number: paymentMethod.receipt_number,
              },
              relationships: {
                payment: {
                  data: {
                    type: 'payments',
                    id: payment.data.id,
                  },
                },
                'payment-method-type': {
                  data: {
                    type: 'payment-method-types',
                    id: paymentMethod.paymentMethodTypeId,
                  },
                },
                ...(paymentMethod.officeBankId
                  ? {
                      'office-bank': {
                        data: {
                          type: 'office-banks',
                          id: paymentMethod.officeBankId,
                        },
                      },
                    }
                  : {}),
              },
            },
          },
        });
      });

      await Promise.all(requests);
    },
    async loadOrder() {
      this.loadingOrder = true;

      const orderId = this.$route.params.id;

      const [res, error] = await this.request(`/api/v1/orders/${orderId}`, {
        params: {
          'fields[orders]':
            'origin_code,origin_warehouse_code,area_code,grand_total_price,order_shipment,is_paid,is_valid_for_packing,destination-office,acc_payment_reason,cashier_payment_amount,payment_amount',
          'fields[offices]':
            'office_type,code,name,temp_code,is_active,order_balance_available',
          include: 'destination-office',
        },
      });

      if (!error) {
        this.order = res;
      }

      this.loadingOrder = false;
    },
    async loadPayments() {
      this.loadingPayments = true;

      const orderId = this.$route.params.id;

      const [res, error] = await this.request(
        `/api/v1/orders/${orderId}/payments`,
        {
          params: {
            'fields[payments]':
              'is_first_payment,is_verified,total_amount,cashier_total_amount,code,destination_office_code,origin_warehouse_code,state,createdAt',
          },
        }
      );

      if (!error) {
        this.payments = res;
      }

      this.loadingPayments = false;
    },
    async onConfirmDelete() {
      this.loadingCancel = true;

      const [, error] = await this.request(
        `/api/v1/orders/${this.order.data.id}/-actions/cancelPayment`,
        {
          method: 'patch',
          data: {
            cancel_payment_description: 'Cancel',
          },
        }
      );

      if (!error) {
        this.$router.push({ name: 'pembayaran.ke-stockist' });
      }

      this.loadingCancel = false;
    },
    async onCreatePayment(paymentMethods) {
      this.loadingCreatePayment = true;

      const [payment, error] = await this.request(`/api/v1/payments`, {
        method: 'post',
        data: {
          data: {
            type: 'payments',
            relationships: {
              order: {
                data: {
                  type: 'orders',
                  id: this.order.data.id,
                },
              },
            },
          },
        },
      });

      if (!error) {
        await this.createPaymentMethods(payment, paymentMethods);

        this.loadPayments();
      }

      this.loadingCreatePayment = false;
    },
    async onSubmitAccAtasan() {
      this.loadingAccAtasan = true;

      const [, err] = await this.request(
        `/api/v1/orders/${this.order.data.id}/-actions/acc-payment`,
        {
          method: 'patch',
          data: {
            acc_payment_reason: this.accAtasanReason,
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.$router.push({ name: 'pembayaran.ke-stockist' });
      }

      this.loadingAccAtasan = false;
    },
    async onVerifiedPayment() {
      await Promise.all([this.loadOrder(), this.loadPayments()]);

      if (this.order.data.attributes.is_paid) {
        this.onPrintInvoice();
      }
    },
    async onPrintInvoice() {
      this.loadingPrintInvoice = true;

      const [res, err] = await this.request(
        `/api/v1/payments/${this.payments.data[0].id}/payment-methods`,
        {
          params: {
            'filter[canceled]': false,
            'fields[payment-methods]':
              'payment_method_type,cashier_payment_amount,payment_amount,is_used',
          },
        }
      );

      if (!err) {
        this.printInvoice.paymentMethods = res.data.filter(
          (item) => item.attributes.is_used
        );

        this.$refs.printInvoince.generatePdf();
      }

      this.loadingPrintInvoice = false;
    },
  },
  async created() {
    await Promise.all([this.loadOrder(), this.loadPayments()]);

    this.pageLoaded = true;
  },
};
</script>
