<template>
  <div class="flex gap-2">
    <base-dropdown v-if="dropdown" width="200px">
      <template #toggle="{ toggle }">
        <base-button color="white" size="sm" @click="toggle">{{
          currentPeriod ? currentPeriod.attributes.name : 'Periode'
        }}</base-button>
      </template>

      <template #content>
        <div class="space-y-4 p-4">
          <base-input :with-label="withLabel" :label="withLabel ? 'Tahun' : ''">
            <base-select
              :shadow="false"
              :options="years"
              expand
              expand-md
              v-model="year"
              @change="onChangeYear"
            />
          </base-input>
          <base-input :with-label="withLabel" :label="withLabel ? 'Bulan' : ''">
            <base-select
              :shadow="false"
              :options="months"
              expand
              expand-md
              v-model="month"
              @change="$emit('change')"
            />
          </base-input>
        </div>
      </template>
    </base-dropdown>
    <template v-else>
      <base-input :with-label="withLabel" :label="withLabel ? 'Tahun' : ''">
        <base-select
          :shadow="false"
          :expand="expand"
          :options="years"
          v-model="year"
          @change="onChangeYear"
        />
      </base-input>
      <base-input :with-label="withLabel" :label="withLabel ? 'Bulan' : ''">
        <base-select
          :shadow="false"
          :expand="expand"
          :options="months"
          v-model="month"
          @change="$emit('change')"
        />
      </base-input>
    </template>
  </div>
</template>

<script>
import { getListPastNYear } from '@/services/date.service.js';
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import BaseDropdown from '@/components/base/BaseDropdown.vue';

export default {
  mixins: [requestMixin],
  components: { BaseDropdown },
  props: {
    value: String,
    withLabel: Boolean,
    dropdown: Boolean,
    expand: Boolean
  },
  emits: ['input', 'change'],
  data() {
    return {
      year: new Date().getFullYear(),
      periods: {
        data: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/getUser',
    }),
    month: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    months() {
      return [
        {
          key: 'placeholder',
          label: 'Periode Bulan',
          value: null,
          disabled: true,
        },
        ...this.periods.data.map((month) => ({
          key: month.attributes.name,
          label: month.attributes.name.split(' ')[0],
          value: month.id,
        })),
      ];
    },
    years() {
      return [
        {
          key: 'placeholder',
          label: 'Periode Tahun',
          value: null,
          disabled: true,
        },
        ...getListPastNYear(10).map((year) => ({
          key: year,
          label: year,
          value: year,
        })),
      ];
    },
    currentPeriod() {
      return this.periods.data.find((period) => period.id === this.month);
    },
  },
  methods: {
    async loadPeriods() {
      const [res, err] = await this.request(`/api/v1/periods`, {
        params: {
          'filter[year]': this.year,
        },
      });

      if (!err) {
        this.periods = res;
      }
    },
    async onChangeYear() {
      await this.loadPeriods();

      if (this.periods.data.length) {
        this.month = this.periods.data[0].id;

        this.$emit('change');
      } else {
        this.month = null;
      }
    },
  },
  async mounted() {
    this.year = new Date().getFullYear();

    await this.loadPeriods();
  },
};
</script>
